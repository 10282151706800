<template>
  <!--  修改邮箱    -->
  <a-modal
    :visible="visible"
    :title="'绑定邮箱'"
    centered
    :destroyOnClose="true"
    @cancel="close"
  >
    <!--   绑定邮箱form :rules="safeRules" -->
    <a-form
      ref="safeRef"
      :model="form.safeForm"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item label="邮箱地址" v-bind="validateInfos.account">
        <a-input
          autocomplete="off"
          v-model:value="form.safeForm.account"
          :placeholder="'请输入邮箱地址'"
        />
      </a-form-item>
      <a-form-item label="验证码" v-bind="validateInfos.code">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input
              v-model:value="form.safeForm.code"
              placeholder="请输入验证码"
            />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <template v-slot:footer>
      <a-button @click="close">取消</a-button>

      <!--      <a-button v-if="showSafe" type="primary" @click="gotoOk">-->
      <!--        下一步-->
      <!--      </a-button>-->
      <a-button type="primary" @click="handleOk"> 确认 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Col, Form, message, Row } from 'ant-design-vue';
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import personalApi from '@/api/personal';
import { validEmail } from '@/utils/validation';
import personalApi from '@/api/personal';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const formRef = ref();
    const safeRef = ref();
    // const router = useRouter();
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      visiblePE: false,
      // showSafe: true,
      loading: false,
      // safeForm: { account: '', code: '' },
      timer: null,
      showVal: null,
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      activeUpload: '',
    });
    const form = reactive({
      sex: '',
      safeForm: { account: '', code: '' },
      id: userId ? userId : '',
      // area: null,
      userName: '',
      avatar: '',
    });

    watch(
      () => props.init,
      value => {
        console.log('value', value);
      }
    );

    const required = { required: true, whitespace: true, message: '不能为空' };
    // 基本信息校验 resetFields
    const { validateInfos, validate } = Form.useForm(form.safeForm, {
      account: [
        required,
        {
          validator: (rule, value) => {
            if (value) {
              if (validEmail(value)) {
                return Promise.resolve();
              }
              // 或者邮箱
              return Promise.reject('请输入合法的邮箱地址');
            }
            return Promise.reject();
          },
        },
      ],
      code: [required],
    });

    onMounted(() => {});

    return {
      ...toRefs(state),
      validateInfos,
      form,
      formRef,
      safeRef,
      userId,
      // safeRules: {
      //   account: [
      //     required,
      //     {
      //       validator: (rule, value) => {
      //         validEmail(value)
      //           ? Promise.resolve()
      //           : Promise.reject('请输入合法的邮箱地址');
      //       },
      //     },
      //   ],
      //   code: required,
      // },

      // gotoOk() {
      //   state.showSafe = false;
      // },
      close() {
        context.emit('fnOk', false);
        // state.showSafe = true;
        // state.loading = false;
      },

      handleGetCode() {
        validate('account').then(() => {
          state.btn = { disabled: true, title: '重新获取(60s)' };
          let count = 60;
          state.timer = setInterval(() => {
            if (count === 1) {
              state.btn = { disabled: false, title: '获取验证码' };
              clearInterval(state.timer);
              state.timer = null;
            } else {
              count--;
              state.btn.title = `重新获取(${count}s)`;
            }
          }, 1000);
          let parmas = {
            email: form.safeForm.account,
            userId: userId,
            verifyCode: form.safeForm.code,
          };
          personalApi.sendEmailVerifyCode('', parmas).then(res => {
            if (res) {
              message.success('验证码已发送，请注意查收');
            }
          });
        });
      },
      handleOk() {
        validate().then(() => {
          if (state.loading) return;
          state.loading = true;

          let parmas = {
            email: form.safeForm.account,
            userId: userId,
            verifyCode: form.safeForm.code,
          };
          personalApi
            .updateOrBindUserEmail('', parmas)
            .then(res => {
              if (res) {
                message.success('操作成功');
                // 请求成功或者失败时关闭
                context.emit('fnOk', false);
                state.loading = false;
              }
            })
            .catch(() => {
              state.loading = false;
            });
        });
      },
    };
  },
};
</script>

<style scoped></style>
